<template>
  <v-card>
    <v-toolbar
      dark
      color="secondary"
      style="position: sticky; top: 0; z-index: 1"
    >
      <v-btn
        icon
        dark
        @click="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ type === "add" ? "Add" : "Edit" }} Song</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          text
          type="submit"
          :form="`song-form-${type}`"
          class="grey lighten-2 secondary--text"
        >
          Save
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-container>
      <v-form
        ref="songForm"
        :id="`song-form-${type}`"
        class="mx-auto pt-4"
        v-model="valid"
        :lazy-validation="true"
        @submit.prevent="onSubmit"
      >
        <v-row>
          <v-col
            class="py-0"
          >
            <v-text-field
              v-model="link"
              label="YouTube Link *"
              :rules="[rules.required, yt_id ? true : 'Invalid URL']"
              prepend-icon="mdi-youtube"
              @keyup="getThumbnailURL()"
            ></v-text-field>
          </v-col>
          <img
            v-if="thumbnailLink"
            width="100px"
            height="75px"
            :src="thumbnailLink"
            class="ma-1"
          >
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <v-text-field
              v-model="title"
              label="Song Title *"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <v-text-field
              v-model="title_bn"
              label="Title in Bengali *"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <!-- <v-text-field
              v-model="singer"
              label="Singer *"
              :rules="[rules.required]"
            ></v-text-field> -->
            <v-combobox
              v-model="singer"
              label="Singer *"
              :items="singerList"
              :rules="[rules.required]"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <v-combobox
              v-model="composer"
              label="Composer *"
              :items="composerList"
              :rules="[rules.required]"
            ></v-combobox>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <v-combobox
              v-model="lyricist"
              label="Lyricist *"
              :items="lyricistList"
              :rules="[rules.required]"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <v-text-field
              v-model="album"
              label="Album *"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <v-combobox
              v-model="production"
              label="Production Company"
              :items="productionList"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            md="3"
            class="py-0"
          >
            <v-combobox
              v-model="release_year"
              label="Year of Release"
              :items="getYearOptions()"
            ></v-combobox>
          </v-col>
          <v-col
            cols="3"
            class="py-0"
          >
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <v-input
              :value="rating"
              :label="this.$vuetify.breakpoint.mdAndUp ? 'Rating *' : ''"
              :rules="[rules.required]"
              class="mt-3"
            >
              <v-rating
                v-model="rating"
                length="10"
                :color="ratingColor"
                :class="this.$vuetify.breakpoint.mdAndUp ? 'ml-3' : ''"
                density="compact"
              ></v-rating>
            </v-input>
          </v-col>
        </v-row>
        <v-textarea
          label="Additional Information (if any)"
          v-model="additionalInfo"
          outlined
          rows="2"
          class="pt-4"
        ></v-textarea>
        <v-textarea
          label="Lyrics"
          v-model="lyrics_bn"
          rows="10"
          outlined
          class="pt-4"
        ></v-textarea>
      </v-form>
    </v-container>
    <v-dialog
      v-model="addConfirmation"
      max-width="400"
    >
      <confirmation-dialog
        title="Are you sure?"
        @cancel="addConfirmation = false"
        @ok="onConfirm"
      >
        <div>
          <p>
            Is the entry correct?
          </p>
        </div>
      </confirmation-dialog>
    </v-dialog>
  </v-card>
</template>

<script>
import { required } from '@/utils/formRules'
import { createId } from '@/utils/methods'
import { mapActions } from 'vuex'
import axios from 'axios'

const GG = 'Goutam Ghosal'

export default {
  props: {
    type: {
      type: String,
      default: 'add'
    },
    singerList: {
      type: Array,
      required: true,
      default () {
        return [GG]
      }
    },
    composerList: {
      type: Array,
      required: true,
      default () {
        return [GG]
      }
    },
    lyricistList: {
      type: Array,
      required: true,
      default () {
        return [GG]
      }
    },
    productionList: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    editData: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    valid: true,
    rules: {
      required
    },

    itemId: '', // Applicable for edit action
    song_id: '',
    title: '',
    title_bn: '',
    singer: '',
    composer: '',
    lyricist: '',
    album: '',
    production: '',
    release_year: '',
    rating: 0,
    link: '',
    yt_id: '',
    thumbnailLink: '',
    additionalInfo: '',
    lyrics_bn: '',

    addConfirmation: false
  }),
  computed: {
    ratingColor () {
      return this.rating > 8 ? 'success'
        : this.rating > 6 ? 'info'
          : this.rating > 3 ? 'warning'
            : this.rating > 0 ? 'error'
              : 'accent'
    }
  },
  watch: {},
  created () {},
  methods: {
    ...mapActions(['addSong', 'editSong', 'getLyric', 'addLyric', 'editLyric']),
    onSubmit () {
      if (this._validateData()) this.addConfirmation = true
    },
    onConfirm () {
      this.type === 'add' ? this.add() : this.edit()
    },
    async add () {
      this.addConfirmation = false
      if (this._validateData()) {
        this.showSpinner()
        const songId = createId(20)
        try {
          const payload = {
            song_id: songId,
            title: this.title,
            title_bn: this.title_bn,
            singer: this.singer,
            composer: this.composer,
            lyricist: this.lyricist,
            album: this.album,
            production: this.production,
            release_year: this.release_year,
            rating: this.rating,
            link: this.link,
            yt_id: this.yt_id
          }
          await this.addSong(payload)
          this.showToast({ text: 'Song added successfully' })
          const lyric = this.lyrics_bn.trim()
          if (lyric) {
            await this.addLyric({
              song_id: songId,
              lyrics_bn: this.lyrics_bn
            })
          }
          this._resetData()
        } catch (error) {
          this.showToast({ text: error, color: 'error' })
        } finally {
          this.hideSpinner()
        }
      }
    },
    async edit () {
      if (this._validateData()) {
        this.showSpinner()

        try {
          await this.editSong({
            id: this.itemId,
            payload: {
              title: this.title,
              title_bn: this.title_bn,
              singer: this.singer,
              composer: this.composer,
              lyricist: this.lyricist,
              album: this.album,
              production: this.production,
              release_year: this.release_year,
              rating: this.rating,
              link: this.link,
              yt_id: this.yt_id
            }
          })
          this.showToast({ text: 'Song edited successfully' })
          const lyric = this.lyrics_bn.trim()
          if (lyric) {
            const snapshot = await this.getLyric(this.song_id)
            if (!snapshot.empty) {
              await this.editLyric({
                id: snapshot.docs[0].id,
                payload: { lyrics_bn: this.lyrics_bn }
              })
            } else {
              await this.addLyric({
                song_id: this.song_id,
                lyrics_bn: this.lyrics_bn
              })
            }
          }
          this._resetData()
          this.$emit('close')
        } catch (error) {
          this.showToast({ text: error, color: 'error' })
        } finally {
          this.hideSpinner()
        }
      }
    },
    closeDialog () {
      this._resetData()
      this.$emit('close')
    },
    _validateData () {
      return this.$refs.songForm.validate() && this.thumbnailLink
    },
    _resetData () {
      this.$refs.songForm.reset()
      this.rating = 0
      this.thumbnailLink = ''
    },
    async getThumbnailURL () {
      try {
        const response = await axios.get(`https://www.youtube.com/oembed?url=${this.link}`)
        this.thumbnailLink = response.data.thumbnail_url
        this.yt_id = this.thumbnailLink.split('/')[4] // TODO check
      } catch (err) {
        this.thumbnailLink = ''
        this.yt_id = ''
      }
    },
    getYearOptions () {
      const retList = []
      let year = new Date().getFullYear()
      while (year >= 2000) {
        retList.push(year--)
      }
      return retList
    }
  },
  components: {
    ConfirmationDialog: () =>
      import('@/components/utils/dialog/ConfirmationTemplate')
  }
}
</script>
